:root {
  /* Colors */
  --primary-color: #81bcfc;
  --primary-dark: #0056b3;
  --error-color: #dc3545;
  --success-color: #28a745;
  --text-color: #2c3e50;
  --text-light: #6c757d;
  --background-color: #f8f9fa;
  --border-color: #dee2e6;
  
  /* Spacing */
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 12px;
  --spacing-lg: 16px;
  --spacing-xl: 24px;
  
  /* Border */
  --border-radius-sm: 4px;
  --border-radius: 8px;
  --border-radius-lg: 12px;
  
  /* Typography */
  --font-size-sm: 14px;
  --font-size-base: 16px;
  --font-size-lg: 18px;
  --font-size-xl: 24px;
  
  /* Transitions */
  --transition-fast: 150ms ease;
  --transition-base: 300ms ease;
  --transition-slow: 500ms ease;
  
  /* Shadows */
  --shadow-sm: 0 1px 3px rgba(0,0,0,0.12);
  --shadow-base: 0 2px 4px rgba(0,0,0,0.1);
  --shadow-lg: 0 4px 6px rgba(0,0,0,0.1);
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-unit);
}

.grid {
  display: grid;
  gap: var(--spacing-unit);
  grid-template-columns: 1fr;
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
