/* Import base styles */
@import './styles/base.css';

.App {
  min-height: 100vh;
  background-color: var(--background-color);
  color: var(--text-color);
  /* padding: var(--spacing-md); */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.calculator-layout {
  display: grid;
  gap: var(--spacing-xl);
  max-width: 900px;
  margin: 0 auto;
  padding: var(--spacing-md);
  grid-template-columns: 1fr;
}

@media (min-width: 900px) {
  .App {
    padding: var(--spacing-xl);
  }

  .form-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-lg);
  }
}

.section {
  margin-bottom: var(--spacing-xl);
  padding: var(--spacing-lg);
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-base);
  transition: transform var(--transition-base);
}

.section:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-lg);
}

.header {
  text-align: center;
  margin-bottom: var(--spacing-lg);
}

.calculator-form {
  max-width: 800px;
  margin: 0 auto;
  padding: var(--spacing-lg);
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.form-group {
  margin-bottom: var(--spacing-lg);
}

.form-group .label-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  position: relative;
}

.form-group .label-text {
  font-weight: 500;
}

.form-group .tooltip-trigger {
  font-size: 0.85em;
  color: var(--text-light);
  cursor: pointer;
  text-decoration: underline;
  margin-left: auto;
  position: relative;
}

.form-group .tooltip-trigger:hover {
  color: var(--primary-color);
}


/* Base input styling */

/* Input group and prefix/suffix styling */
.input-group {
  position: relative;
  width: 100%;
}

.input-prefix,
.input-suffix {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-light);
  pointer-events: none;
  z-index: 1;
  font-size: var(--font-size-base);
  width: 20px;
  text-align: center;
}

.input-prefix {
  left: 8px;
}

.input-suffix {
  right: 8px;
}

/* Currency input specific styling */
.currency-input {
  padding-left: 32px !important;
  font-variant-numeric: tabular-nums;
}

.currency-input::placeholder {
  opacity: 0.5;
}

.percentage-input {
  padding-right: 24px;
}

.error-message {
  color: var(--error-color);
  font-size: 14px;
  margin-top: 4px;
}

.submit-button {
  width: 100%;
  padding: var(--spacing-unit);
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: var(--border-radius);
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

.results-container {
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-base);
  padding: var(--spacing-lg);
  margin-top: var(--spacing-lg);
  transition: transform var(--transition-base);
}

.results-container:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-lg);
}

@media (max-width: 899px) {
  .results-container,
  .disclaimer {
    margin: var(--spacing-md) 0;
    padding: var(--spacing-md);
  }
}

.recommendation {
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.positive {
  background-color: #d4edda;
  color: #155724;
}

.negative {
  background-color: #f8d7da;
  color: #721c24;
}

.highlight {
  font-weight: bold;
}

.tag {
  display: inline-block;
  background-color: var(--primary-dark);
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  margin-left: 8px;
  font-weight: normal;
}

.details {
  padding: 15px;
  border-radius: 5px;
}

.animate-pop-in {
  animation: popIn 0.5s;
}

.animate-fade-in {
  animation: fadeIn 1s;
}

.animate-slide-in {
  animation: slideIn 0.5s;
}

@keyframes popIn {
  0% { transform: scale(0.8); opacity: 0; }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes slideIn {
  0% { transform: translateY(20px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}

.disclaimer {
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-base);
  padding: var(--spacing-lg);
  margin-top: var(--spacing-lg);
  font-size: 0.9rem;
  text-align: left;
  transition: transform var(--transition-base);
}

.disclaimer:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-lg);
}

.disclaimer h3 {
  margin-top: 0;
  color: #721c24;
}

.disclaimer p {
  margin-bottom: 1rem;
}

/* Form styles */
.form-container {
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-base);
  padding: var(--spacing-lg);
}

.form-grid {
  display: grid;
  gap: var(--spacing-lg);
  grid-template-columns: 1fr;
  margin-bottom: 12px;
}

@media (min-width: 900px) {
  .form-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.form-group {
  position: relative;
  width: 100%;
}

.floating-label {
  position: absolute;
  left: var(--spacing-sm);
  top: 50%;
  transform: translateY(-50%);
  transition: all var(--transition-fast);
  pointer-events: none;
  color: var(--text-light);
  background-color: white;
  padding: 0 var(--spacing-xs);
  font-size: var(--font-size-sm);
}

.form-group input:focus ~ .floating-label,
.form-group input:not(:placeholder-shown) ~ .floating-label,
.form-group select:focus ~ .floating-label,
.form-group select:not([value=""]) ~ .floating-label {
  top: 0;
  transform: translateY(-50%) scale(0.85);
  color: var(--primary-color);
}

input[type="text"],
input[type="number"],
select {
  width: 100%;
  padding: 8px;
  border: 2px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-base);
  transition: all var(--transition-fast);
  background-color: white;
  -moz-appearance: textfield;
  box-sizing: border-box;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="text"]:hover,
input[type="number"]:hover,
select:hover {
  border-color: var(--primary-color);
}

input[type="text"]:focus,
input[type="number"]:focus,
select:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
}


.input-addon {
  position: absolute;
  right: var(--spacing-md);
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-light);
  pointer-events: none;
  font-size: var(--font-size-sm);
}

.error-message {
  color: var(--error-color);
  font-size: var(--font-size-sm);
  margin-top: var(--spacing-xs);
}

.error-banner {
  background-color: var(--error-color);
  color: white;
  padding: var(--spacing-md);
  border-radius: var(--border-radius-sm);
  margin-bottom: var(--spacing-lg);
}

input[type="text"]:focus,
input[type="number"]:focus,
select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
}

button[type="submit"] {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

/* Error message style */
div[style*="color: red"] {
  margin-top: 5px;
  font-size: 14px;
}

/* Tooltip styles */
.tooltip-trigger {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-left: 8px;
  color: var(--text-light);
  font-size: 14px;
}

.tooltip-trigger:hover {
  color: var(--primary-color);
}

.tooltip {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  width: 250px;
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-lg);
  padding: var(--spacing-md);
  font-size: 14px;
  animation: tooltipFadeIn 0.2s ease-in-out;
  margin-top: 4px;
}

.tooltip-close {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: var(--text-light);
  padding: 4px 8px;
  border-radius: 50%;
}

.tooltip-close:hover {
  background-color: var(--background-color);
  color: var(--text-color);
}

.tooltip-content {
  padding-right: 20px;
  color: var(--text-color);
}

@keyframes tooltipFadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 899px) {
  .tooltip {
    width: 200px;
  }
}
